
















import { Component, Mixins } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
// components
import MaterialForm from '@/components/forms/depository/MaterialForm.vue'
// store
import AuthModule from '@/store/modules/auth'
import { IUsefulMaterialForm } from '@/store/types/forms'
import { UsefulMaterialPostBody, UsefulMaterialType } from '@/store/types'
// utils
import { transformRequestMaterialForm } from '@/utils/functions'

@Component({
  components: {
    MaterialForm,
  },
})
export default class DepositoryCourseItemMaterialsCreate extends Mixins(NotifyMixin) {

  private courseID = this.$route.params.courseID
  private form: IUsefulMaterialForm = {
    content: '',
    courseIds: [],
    mediaIds: [],
    publishDatetime: '',
    title: '',
    type: '' as UsefulMaterialType,
  }

  private get breadcrumbs () {
    return [
      { name: 'База знаний', path: { name: 'manager.bank.depository' } },
      { name: this.course?.name, path: { name: 'manager.bank.depository.courses.item.materials', params: { courseID: this.course?.value } } },
    ]
  }

  private get course () {
    return ManagerDepositoryModule.course
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    if (this.course && this.course.value !== +this.courseID || !this.course) {
      ManagerDepositoryModule.fetchCourse(+this.courseID)
        .catch((error) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.bank.depository.courses' })
        })
    }
  }

  private createMaterial(form: IUsefulMaterialForm) {
    ManagerDepositoryModule.createMaterial({ body: transformRequestMaterialForm(form, this.isLocalTimezone) as UsefulMaterialPostBody, courseId: +this.courseID })
      .then(() => {
        this.notifySuccess('Материал успешно создан.')
        this.$router.push({ name: 'manager.bank.depository.courses.item', params: { courseID: this.courseID } })
      })
      .catch(this.notifyError)
  }

}
